import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import {
  Document as DocumentInterface,
  EditableDocument as EditableDocumentInterface,
} from '@ankor-io/common/proposal/Document'
import { Events } from '@ankor-io/common/proposal/Events'
import { EditableProposal, JsonProposal, Proposal, ProposalItem, Template } from '@ankor-io/common/proposal/Proposal'

/**
 * The proposal interface implementation
 */
export class ProposalImpl implements Proposal {
  readonly uri: string
  readonly template: Template
  readonly document: DocumentInterface
  readonly proposalItems: ProposalItem[]
  readonly tags: string[]
  readonly flow: 'NEEDS_INIT' | 'INITIALIZED'
  readonly indexable: boolean
  readonly internalName: string
  readonly hasItems: boolean
  /**
   * @deprecated The proposal client facing name - this is deprecated and removed from the UI, but still exists in the back-end
   */
  readonly externalName?: string

  constructor(
    template: Template,
    document: DocumentInterface,
    uri: string,
    proposalItems: ProposalItem[],
    flow: 'NEEDS_INIT' | 'INITIALIZED',
    indexable: boolean,
    tags: string[],
    internalName: string,
    /**
     * @deprecated The proposal client facing name - this is deprecated and removed from the UI, but still exists in the back-end
     */
    externalName?: string,
  ) {
    this.uri = uri
    this.template = template
    this.document = document
    this.proposalItems = proposalItems
    this.internalName = internalName
    this.externalName = externalName
    this.flow = flow
    this.indexable = indexable
    this.tags = tags
    this.hasItems = proposalItems.length > 0
  }

  /**
   * Generate a json representation of this proposal. Useful for diff sync
   *
   * @returns a json representation of the proposal at its current state
   */
  toJson(): JsonProposal {
    return {
      uri: this.uri,
      template: JSON.parse(JSON.stringify(this.template)),
      document: this.document.toJson(),
      proposalItems: this.proposalItems,
      internalName: this.internalName,
      externalName: this.externalName,
      flow: this.flow,
      indexable: this.indexable,
      tags: this.tags,
      hasItems: this.hasItems,
    }
  }
}

/**
 * The editable Proposal interface implementation
 */
export class EditableProposalImpl implements EditableProposal {
  readonly uri: string
  readonly template: Template
  readonly document: EditableDocumentInterface
  readonly proposalItems: ProposalItem[]
  readonly flow: 'NEEDS_INIT' | 'INITIALIZED'
  readonly indexable: boolean
  readonly tags: string[]
  readonly internalName: string
  readonly hasItems: boolean
  /**
   * @deprecated The proposal client facing name - this is deprecated and removed from the UI, but still exists in the back-end
   */
  readonly externalName?: string
  // can be sections and or slides
  private initializingBlocks: string[]
  private initializationTimeout: NodeJS.Timeout | undefined
  private pauseListener: Runnable<string>
  private unpauseListener: Runnable<string>

  constructor(
    template: Template,
    document: EditableDocumentInterface,
    uri: string,
    proposalItems: ProposalItem[],
    flow: 'NEEDS_INIT' | 'INITIALIZED',
    indexable: boolean,
    tags: string[],
    internalName: string,
    /**
     * @deprecated The proposal client facing name - this is deprecated and removed from the UI, but still exists in the back-end
     */
    externalName?: string,
  ) {
    this.uri = uri
    this.template = template
    this.document = document
    this.proposalItems = proposalItems
    this.internalName = internalName
    this.externalName = externalName
    this.flow = flow
    this.indexable = indexable
    this.tags = tags
    this.initializingBlocks = []
    this.initializationTimeout = undefined
    this.hasItems = proposalItems.length > 0

    // let's get the dispatcher so we can listen to some events
    const dispatcher = useAppDispatcher().get()

    this.pauseListener = (id: string) => {
      // let's make sure we set a timeout if that's not defined so
      // if a section is haning we don't block the page
      if (this.initializationTimeout === undefined) {
        this.initializationTimeout = setTimeout(() => {
          this.initializingBlocks = []
          dispatcher.dispatchEvent(Events.SYNC)
          clearTimeout(this.initializationTimeout)
        }, 10000)
      }
      // on pause let's push the id of the section requesting to pause
      this.initializingBlocks.push(id)
    }

    this.unpauseListener = (id: string) => {
      // on unpause let's remove the id of the section requesting to unpause
      this.initializingBlocks = this.initializingBlocks.filter((_id: string) => id !== _id)
      if (!this.isInitializing()) {
        // we can clear the timeout, we have got all the sections unpausing
        clearTimeout(this.initializationTimeout)
        // let's dispatch a sync
        dispatcher.dispatchEvent(Events.SYNC)
      }
    }
    // let's add the event listeners
    dispatcher.addEventListener<string>(Events.PAUSE, this.pauseListener)
    dispatcher.addEventListener<string>(Events.UNPAUSE, this.unpauseListener)
  }

  destroy(): void {
    const dispatcher = useAppDispatcher().get()
    dispatcher.removeEventListener<string>(Events.PAUSE, this.pauseListener)
    dispatcher.removeEventListener<string>(Events.UNPAUSE, this.unpauseListener)
  }

  isInitializing(): boolean {
    return this.initializingBlocks.length > 0
  }

  /**
   * Generate a json representation of this proposal. Useful for diff sync
   *
   * @returns a json representation of the proposal at its current state
   */
  toJson(): JsonProposal {
    return {
      uri: this.uri,
      template: JSON.parse(JSON.stringify(this.template)),
      document: this.document.toJson(),
      proposalItems: this.proposalItems,
      internalName: this.internalName,
      externalName: this.externalName,
      flow: this.flow,
      indexable: this.indexable,
      tags: this.tags,
      hasItems: this.hasItems,
    }
  }
}
